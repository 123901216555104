<template>
  <Acl :permissions="['posts-index']">
    <b-row no-gutters class="align-items-center justify-content-between mb-3">
      <b-col cols="7" class="mb-3 mb-lg-0 pb-2 pt-2 pt-lg-0 pb-lg-0">
        <a href="javascript:;"
          @click.prevent="() => {
            filters.is_published = null, filters.scheduled = null, fetch(filters)
          }"
          :class="filters.is_published === null && filters.scheduled === null ? 'text-dark font-weight-bold' : 'text-primary'">
          All
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => {
            filters.is_published = '1', filters.scheduled = null, fetch(filters)
          }"
          :class="filters.is_published === '1' && filters.scheduled === null? 'text-dark font-weight-bold' : 'text-primary'">
          Published
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => {
            filters.scheduled = '1', filters.is_published = '0', fetch(filters)
          }"
          :class="filters.scheduled === '1' && filters.is_published === '0'? 'text-dark font-weight-bold' : 'text-primary'">
          Scheduled
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => {
            filters.is_published = '0', filters.scheduled = null, fetch(filters)
          }"
          :class="filters.is_published === '0' && filters.scheduled === null ? 'text-dark font-weight-bold' : 'text-primary'">
          Draft
        </a>
      </b-col>
      <b-col cols="5" lg="2" class="text-right pr-lg-2 mb-3 mb-lg-0">
        <b-button v-show="selectedPosts.length" @click.prevent="showDeleteConfirm(selectedPosts)" size="sm" variant="outline-danger"><i class="fe fe-trash"></i> Delete Selected</b-button>
      </b-col>
      <b-col lg="3" class="text-right">
        <a-form :form="form" @submit.prevent="search" layout="inline">
          <a-input-search v-model="filters.q" placeholder="Search Posts"></a-input-search>
        </a-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="px-3 card">
          <a-table
            :row-selection="{ selectedRowKeys: selectedPosts, onChange: onSelectChange }"
            :loading="loading"
            :columns="columns"
            :dataSource="posts"
            :class="$style.table"
            :pagination="pagination"
            :scroll="{ x: 1000 }"
            @change="tableChanged">
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              class="px-2 py-2"
            >
              <a-form-model-item class="mb-0 py-2">
                <a-range-picker
                  :value="filters.published_at"
                  format="YYYY-MM-DD"
                  @change="(date, dateString) => onDateChange(date, dateString, setSelectedKeys)">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date">
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-model-item>

              <b-row class="border-top mt-2 pt-2 mx-n2 px-2">
                <b-col cols="6">
                  <a href="javascript:;"
                    class="text-primary"
                    @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">OK</a>
                </b-col>
                <b-col cols="6" class="text-right">
                  <a href="javascript:;"
                    class="text-primary"
                    @click="() => handleReset(clearFilters)">Reset</a>
                </b-col>
              </b-row>
            </div>
            <template slot="articleTitle" slot-scope="title, item">
              <b-row no-gutters>
                <b-col cols="3">
                  <template v-if="item.heroImage && item.heroImage.url">
                    <img
                      class="rounded"
                      width="50" height="50"
                      :src="`${item.heroImage ? item.heroImage.url+'?tr=w-50,h-50' : '/resources/images/avatars/avatar-2.png'}`"
                      :srcset="`${item.heroImage ? item.heroImage.url : item.heroImage.url}?tr=w-100,h-100 1x, ${item.heroImage ? item.heroImage.url : item.heroImage.url}?tr=w-200,h-200 2x`"
                      :alt="item.heroImage ? item.heroImage.alt : '/resources/images/avatars/avatar-2.png'"/>
                  </template>
                </b-col>
                <b-col class="pl-3">
                  <small v-if="item.highlight" class="text-default label label-default">Highlight</small>
                  <h2 class="mb-0 h6" v-if="!item.editing || !item.editing.status">
                    <router-link :title="`Edit: ${title} `" class="text-gray-6" :to="{ path: `posts/edit/${item.id}`}" v-html="title">
                    </router-link>
                  </h2>
                  <h2 v-else class="mb-0 h6">
                    {{title}}
                  </h2>
                  <small :class="item.is_published ? 'text-success' : item.scheduled_at ? 'text-info' : 'text-warning'">{{ item.is_published ? 'Published' : item.scheduled_at ? 'Scheduled' : 'Draft' }}</small>
                  <div>
                    <a v-if="item.is_published" class="ml-20" :href="`${portalData.url}/read/${item.slug}`" target="_blank" rel="noopener noreferrer">
                      <i class="fe fe-external-link"></i> Open Link
                    </a>
                  </div>
                </b-col>
              </b-row>
            </template>
            <template slot="visitors_count" slot-scope="__meta__, item">
              <div>
                <span class="mr-4">
                  <a href="javascript:;" @click=" () => showDrawer(item.id)">
                      <i class="fe fe-bar-chart-2"></i> {{kFormat(item.__meta__.visitors_count)}}
                  </a>
                </span>
              </div>
            </template>
            <template slot="reposts_count" slot-scope="__meta__, item">
              <div>
                <span class="mr-4">
                  <i class="fe fe-bar-chart-2"></i> {{kFormat(item.__meta__.reposts_count)}}
                </span>
              </div>
            </template>
            <template slot="createdBy" slot-scope="user, item">
              {{ item.authorBy && item.authorBy.full_name ? item.authorBy.full_name : item.createdBy.full_name }}
            </template>
            <template slot="portal" slot-scope="portals">
              <span v-for="portal in portals" :key="portal.id">{{ portal.title }}</span>
            </template>
            <template slot="categories" slot-scope="categories">
              {{ categories.map((category) => {
                return category.title
              }).join(', ') }}
            </template>
            <!--<template slot="tags" slot-scope="tags">
              <div v-if="tags.length">
                <span class="badge badge-info mr-1" v-for="tag in tags" :key="tag.id">{{ tag.name.length > 15 ? `${tag.name.substr(0,15)}...` : tag.name }}</span>
                {{tags.map(m=> m.name).join(', ')}}
              </div>
              <span v-else>-</span>
            </template>-->
            <template slot="date" slot-scope="date, item">
              <!-- published_at: {{ item.published_at }}<br />
              scheduled_at: {{ item.scheduled_at }}<br />
              updated_at: {{ item.updated_at }}<br /> -->
              {{ item.published_at ? 'Published at' : 'Scheduled at' }} {{ item.published_at ? item.published_at : item.scheduled_at | moment("DD MMM YYYY")}}
              <span class="text-muted small">{{ item.published_at ? item.published_at : item.scheduled_at | moment("HH:mm ") }}</span>
              <p class="mb-0 small">created by <span class="text-primary">{{ item.createdBy.full_name }}</span></p>
              <p class="mb-0 small">updated by <span class="text-primary">{{ item.updatedBy.full_name }}</span></p>

              <!--
              <div v-if="item.updated_at" class="mt-2">
                Updated at {{ item.updated_at | moment("DD MMM YYYY")}}
                <span class="text-muted small">{{ item.updated_at | moment("h:mm A") }}</span>
                <p class="mb-0 small">by <span class="text-primary">{{ item.updatedBy.full_name }}</span></p>
              </div>
              -->
            </template>
            <template slot="action" slot-scope="action, item">
              <template v-if="item.editing === null || item.editing.status === false">
                <router-link v-if="item.is_published && portalData.isReadyBulkPublished && user.permissions_id.includes(81)" title="Bulk Published" :to="{ path: `posts/bulk-published/${item.id}`}" class="btn btn-sm btn-info mr-2">
                  <i class="fe fe-upload" />
                </router-link>
                <router-link title="Edit" :to="{ path: `posts/edit/${item.id}`}" class="btn btn-sm btn-primary mr-2">
                  <i class="fe fe-edit" />
                </router-link>
                <button title="Delete" @click="showDeleteConfirm([item.id])" type="button" class="btn btn-sm btn-light">
                  <i class="fe fe-trash" />
                </button>
              </template>
              <template v-else>
                  <p class="small" style="font-size:65%">{{item.editing && item.editing.by ? item.editing.by : ''}} is editing since {{item.editing.date | fromNow}} <br/>
                    <router-link title="Edit" :to="{ path: `posts/edit/${item.id}`}" class="mr-2">
                      <a>Force edit</a>
                    </router-link>
                  </p>
              </template>
            </template>
          </a-table>
          <div :class="$style.tableFooter" class="text-center text-lg-left pb-4 pb-lg-0">
            <span class="text-muted">Showing <b>{{ posts.length }}</b> of <b>{{ new Intl.NumberFormat().format(pagination.total) }}</b> Posts</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <a-drawer
      :title="drawer.title"
      placement="bottom"
      :closable="true"
      :visible="drawer.visible"
      @close="onCloseDrawer"
      height="100%"
    >
      <post-stats :postId="drawer.postId"></post-stats>
    </a-drawer>
  </Acl>

</template>
<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import Acl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import PostStats from './stat.vue'
import { kFormatter } from '../../lib'

export default {
  data() {
    return {
      drawer: {
        postId: null,
        visible: false,
        title: '',
      },
      posts: [],
      postEditing: [],
      form: this.$form.createForm(this),
      pagination: {},
      q: '',
      selectedPosts: [],
      showingPublished: null,
      showingScheduled: null,
      sort: {
        column: 'id',
        value: 'desc',
      },
      filters: {
        scheduled: '',
        start_published_at: '',
        end_published_at: '',
        is_published: '',
        author_ids: [],
        category_ids: [],
        tag_ids: [],
        q: '',
      },
      interval: null,
    }
  },
  filters: {
    format(date) {
      return moment(date).format('D.M.YYYY')
    },
    fromNow(date) {
      return moment(date).fromNow()
    },
  },
  created() {
    this.interval = setInterval(() => this.$forceUpdate(), 1000)
    const vm = this
    vm.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    vm.fetch()
    vm.$store.dispatch('category/FETCH', {
      portal_id: vm.settings.activePortal,
      perPage: 1000,
      sort_column: 'title',
      sort_value: 'asc',
    })
    vm.$store.dispatch('user/FETCH', {
      portal_id: vm.settings.activePortal,
      perPage: 1000,
    })
    vm.$store.dispatch('portal/FETCH_ONE', vm.settings.activePortal)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapState(['settings']),
    ...mapState(['user']),
    ...mapState('post', {
      post: (state) => {
        return state.data
      },
    }),
    ...mapState('portal', {
      portalData: state => state.form,
    }),
    ...mapState('user', {
      users: state => state.data,
    }),
    ...mapState('role', {
      roles: state => state.data,
    }),
    ...mapState('category', {
      categories: state => state.data,
    }),
    loading() {
      return this.$store.state.post.loading
    },
    columns() {
      const vm = this
      const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          width: '30%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'articleTitle' },
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Stats',
          dataIndex: 'visitors_count',
          width: '10%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'visitors_count' },
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Repost',
          dataIndex: 'reposts_count',
          width: '10%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'reposts_count' },
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Author',
          dataIndex: 'createdBy',
          className: 'text-gray-6',
          width: '10%',
          scopedSlots: { customRender: 'createdBy' },
          filters: vm.users
            .filter(f => f.roles.some(s => s.slug !== 'audience'))
            .sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0)).map((user) => {
              return {
                text: user.full_name,
                value: user.id,
              }
            }),
        },
        {
          title: 'Categories',
          dataIndex: 'categories',
          width: '10%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'categories' },
          filters: vm.categories.map((category) => {
            return {
              text: category.title,
              value: category.key,
            }
          }),
        },
        // {
        //   title: 'Tags',
        //   dataIndex: 'tags',
        //   className: 'text-gray-6',
        //   width: '10%',
        //   scopedSlots: { customRender: 'tags' },
        // },
        {
          title: 'Date',
          dataIndex: 'date',
          width: '20%',
          className: 'text-gray-6',
          scopedSlots: {
            customRender: 'date',
            filterDropdown: 'filterDropdown',
          },
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          dataIndex: 'action',
          className: 'text-right',
          width: '130px',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ]
      return columns
    },
  },
  methods: {
    kFormat(num) {
      return kFormatter(num)
    },
    onCloseDrawer() {
      const vm = this
      vm.drawer.visible = false
      this.$store.commit('post/CLEAR_FORM')
    },
    showDrawer(postId) {
      const vm = this
      vm.drawer.postId = postId
      vm.drawer.visible = true
      const post = vm.posts.find(f => `${f.id}` === `${postId}`)
      vm.drawer.title = post.portals.map(m => m.title).join(', ')
    },
    onDateChange(date, dateString, setSelectedKeys) {
      this.filters.published_at = [moment(dateString[0], 'YYYY-MM-DD'), moment(dateString[1], 'YYYY-MM-DD', true)]
      setSelectedKeys(dateString)
    },
    onSelectChange(selectedPosts) {
      this.selectedPosts = selectedPosts
    },
    // Filter & Search
    search() {
      const vm = this
      vm.fetch(vm.filters)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      // this.filters.published_at = [moment(), moment()]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.filters.published_at = []
    },
    fetch(payload = {}) {
      const vm = this
      vm.$store.dispatch('post/FETCH', {
        portal_id: vm.settings.activePortal,
        ...payload,
      }).then((res) => {
        const pagination = {
          ...vm.pagination,
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.pagination = pagination
        vm.posts = res.data.data
      })
    },
    showDeleteConfirm(ids) {
      const vm = this

      Modal.confirm({
        title: 'Are you sure delete this post?',
        content: 'This post will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          ids.forEach((id) => {
            vm.$store.dispatch('post/DELETE', id).then((res) => {
              vm.$notification.success({
                message: 'Post Deleted',
                description: 'Successfully deleted.',
              })
              const index = vm.selectedPosts.indexOf(id)
              if (index > -1) {
                vm.selectedPosts.splice(index, 1)
              }
              vm.fetch()
            })
          })
        },
        onCancel() {},
      })
    },
    tableChanged(pagination, filters, sorter) {
      let order = ''
      let column = sorter.field

      if (sorter.order === 'ascend') {
        order = 'asc'
      } else if (sorter.order === 'descend') {
        order = 'desc'
      }
      if (sorter.field === 'date') {
        column = 'published_at'
      }

      const vm = this
      vm.fetch({
        scheduled: vm.filters.scheduled,
        is_published: vm.filters.is_published,
        q: vm.filters.q,
        category_ids: filters.categories,
        author_ids: filters.createdBy,
        start_published_at: filters.date && filters.date.length ? filters.date[0] : '',
        end_published_at: filters.date && filters.date.length ? filters.date[1] : '',
        page: pagination.current,
        sort_column: (order) ? column : '',
        sort_value: order,
      })
    },
  },
  watch: {
    'settings.activePortal': function() {
      const vm = this
      vm.fetch(vm.filters)
      vm.$store.dispatch('portal/FETCH_ONE', vm.settings.activePortal)
    },
    showingPublished(showingPublished) {
      const vm = this
      vm.filters.is_published = showingPublished
      vm.filters.scheduled = null
      // vm.fetch(vm.filters)
    },
    showingScheduled(showingScheduled) {
      const vm = this
      vm.filters.scheduled = showingScheduled
      vm.filters.is_published = 0
      // vm.fetch(vm.filters)
    },
    posts: function() {
      // console.log('watch')
    },
    post: async function() {
      const vm = this
      vm.posts = vm.post
      // console.log('vm.posts', vm.posts)
      if (vm.posts.length) {
        const postData = JSON.parse(JSON.stringify(vm.posts))
        window.unsubscribe = window.onsnapshot(
          window.collection(window.firebaseFirestore, 'post-editing'),
          (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
              const postId = change.doc.id
              const postIndex = postData.findIndex(f => `${f.id}` === `${postId}`)
              if (postIndex !== -1) {
                // console.log(postIndex)
                // console.log('postData[postIndex]', postData[postIndex])
                if (change.type === 'added' || change.type === 'modified') {
                  // console.log(change.type)
                  postData[postIndex].editing = change.doc.data()
                  try {
                    const userEdit = await vm.$store.dispatch('user/FETCH_ONE', postData[postIndex].editing.userId)
                    postData[postIndex].editing.by = userEdit.data.full_name
                  } catch (error) {
                    postData[postIndex].editing.by = null
                    console.log('failed fetch user edit')
                  }
                }
                if (change.type === 'added') {
                  // postData[postIndex].editing = change.doc.data()
                  vm.posts[postIndex] = postData[postIndex]
                  vm.$store.commit('post/SET_INDEX_DATA', {
                    index: postIndex,
                    data: postData[postIndex],
                  })
                }
                if (change.type === 'modified') {
                  // postData[postIndex].editing = change.doc.data()
                  vm.posts[postIndex] = postData[postIndex]
                  vm.$store.commit('post/SET_INDEX_DATA', {
                    index: postIndex,
                    data: postData[postIndex],
                  })
                }
                if (change.type === 'removed') {
                  console.log(change.type)
                  postData[postIndex].editing = null
                  vm.posts[postIndex] = postData[postIndex]
                  vm.$store.commit('post/SET_INDEX_DATA', {
                    index: postIndex,
                    data: postData[postIndex],
                  })
                }
              }
            })
          },
        )
        // vm.posts = postData
      }
    },
  },
  destroyed() {
    this.$store.commit('post/CLEAR_DATA')
  },
  components: {
    Acl,
    'post-stats': PostStats,
  },
}
</script>
<style lang="scss" module>
// Import mixins
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/mixins";

.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
  &Footer {
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
      margin-bottom: 45px;
    }
  }
}
</style>
